import { GetServerSideProps } from "next";
import type { Page, PageComponent } from "@/services/page";
import { pageService } from "@/services/page/page.service";
import { blogService } from "@/services/page/blog.service";
import { galleryService } from "@/services/page/gallery.service";
import { componentService } from "@/services/page/component.service";
import Head from "next/head";
import LoadingBar from "@/components/ui/LoadingBar";
import HtmlLinksLoader from "@/components/ui/HtmlLinksLoader";
import { ComponentData } from "@/types/components";
import { sanitizeHTML } from "@/lib/utils";

interface PageProps {
  page: Page;
  preview?: boolean;
}


// Dynamic component mapping
import Header from "@/components/Header/Header";
import Footer from "@/components/Footer/Footer";
import AboutSection from "@/components/AboutSection/AboutSection";
import AboutNavigationSection from "@/components/AboutNavigationSection/AboutNavigationSection";
import MedicalAssociationsSection from "@/components/MedicalAssociationsSection/MedicalAssociationsSection";
import FournColumnsSection from "@/components/FournColumnsSection/FournColumnsSection";
import DrDeSilvaOverviewSection from "@/components/DrDeSilvaOverviewSection/DrDeSilvaOverviewSection";
import ArtistrySection from "@/components/ArtistrySection/ArtistrySection";
import PhilanthropySection from "@/components/PhilanthropySection/PhilanthropySection";
import SpecialtyTrainingSection from "@/components/SpecialtyTrainingSection/SpecialtyTrainingSection";
import ConsultationCTA from "@/components/ConsultationCTA/ConsultationCTA";
import TestimonialSection from "@/components/TestimonialSection/TestimonialSection";
import TestimonialSectionGrey from "@/components/TestimonialSectionGrey/TestimonialSectionGrey";
import AboutDrDeSilvaSection from "@/components/AboutDrDeSilvaSection/AboutDrDeSilvaSection";
import AwardsAndRecognitionSection from "@/components/AwardsAndRecognitionSection/AwardsAndRecognitionSection";
import HarleyStreetFaceliftHero from "@/components/HarleyStreetFaceliftHero/HarleyStreetFaceliftHero";
import PageSectionNavigation from "@/components/PageSectionNavigation/PageSectionNavigation";
import WhatIsDeepPlaneFacelift from "@/components/WhatIsDeepPlaneFacelift/WhatIsDeepPlaneFacelift";
import FacialLayersSection from "@/components/FacialLayersSection/FacialLayersSection";
import ConsultationForm from "@/components/ConsultationForm/ConsultationForm";
import FacialSurgeryResultsShowcase from "@/components/FacialSurgeryResultsShowcase/FacialSurgeryResultsShowcase";
import Vfr7DeepPlaneFacelift from "@/components/Vfr7DeepPlaneFacelift/Vfr7DeepPlaneFacelift";
import VfrSevenFaceliftSteps from "@/components/VfrSevenFaceliftSteps/VfrSevenFaceliftSteps";
import InThePress from "@/components/InThePress/InThePress";
import CandidateDeepPlane from "@/components/CandidateDeepPlane/CandidateDeepPlane";
import WhatToExpect from "@/components/WhatToExpect/WhatToExpect";
import ClientResult from "@/components/ClientResult/ClientResult";
import VideosVFR7Results from "@/components/VideosVFR7Results/VideosVFR7Results";
import FrequentlyQuestions from "@/components/FrequentlyQuestions/FrequentlyQuestions";
import DeepPlaneVsStandard from "@/components/DeepPlaneVsStandard/DeepPlaneVsStandard";
import AboutTheAuthor from "@/components/AboutTheAuthor/AboutTheAuthor";
import TitleButtonSection from "@/components/TitleButtonSection/TitleButtonSection";
import HeadingLinkSection from "@/components/HeadingLinkSection/HeadingLinkSection";
import HomeHero from "@/components/HomeHero/HomeHero";
import RequestCall from "@/components/RequestCall/RequestCall";
import WelcomeToPractice from "@/components/WelcomeToPractice/WelcomeToPractice";
import FacialSurgeon from "@/components/FacialSurgeon/FacialSurgeon";
import WhatWeOffer from "@/components/WhatWeOffer/WhatWeOffer";
import VerticalFaceRestore7 from "@/components/VerticalFaceRestore7/VerticalFaceRestore7";
import PatientsSaying from "@/components/PatientsSaying/PatientsSaying";
import Insta from "@/components/Insta/Insta";
import InterestedInForm from "@/components/InterestedInForm/InterestedInForm";
import BlogPreview from "@/components/BlogPreview/BlogPreview";
import QuestionsSections from "@/components/QuestionsSections/QuestionsSections";
import TextOnlySection from "@/components/TextOnlySection/TextOnlySection";
import HeroTitleTextCta from "@/components/HeroTitleTextCta/HeroTitleTextCta";
import HeadingSection from "@/components/HeadingSection/HeadingSection";
import FeaturedLogosSection from "@/components/FeaturedLogosSection/FeaturedLogosSection";
import DeepPlaneFaceliftSection from "@/components/DeepPlaneFaceliftSection/DeepPlaneFaceliftSection";
import ReasonsBlepharoplasty from "@/components/ReasonsBlepharoplasty/ReasonsBlepharoplasty";
import BlogPostSection from "@/components/Blog/BlogPostSection/BlogPostSection";
import BlogIndexSection from "@/components/Blog/BlogIndexSection/BlogIndexSection";
import BlogCategoryListSection from "@/components/Blog/BlogCategoryListSection/BlogCategoryListSection";
import BlogTagListSection from "@/components/Blog/BlogTagListSection/BlogTagListSection";
import BlogAuthorListSection from "@/components/Blog/BlogAuthorListSection/BlogAuthorListSection";
import BlogSearchListSection from "@/components/Blog/BlogSearchListSection/BlogSearchListSection";
import GalleryIndex from "@/components/Gallery/GalleryIndex/GalleryIndex";
import ProcedureSection from "@/components/Gallery/ProcedureSection/ProcedureSection";
import ProcedureHeader from "@/components/Gallery/ProcedureHeader/ProcedureHeader";
import PatientSection from "@/components/Gallery/PatientSection/PatientSection";
import VideoGrid from "@/components/VideoGrid/VideoGrid";
import GalleryMasonry from "@/components/GalleryMasonry/GalleryMasonry";
import LatestFacelift from "@/components/LatestFacelift/LatestFacelift";
import PatientExperience from "@/components/PatientExperience/PatientExperience";
import AreYouReady from "@/components/AreYouReady/AreYouReady";
import GoogleReviews from "@/components/GoogleReviews/GoogleReviews";
import TextGray from "@/components/TextGray/TextGray";
import MediaGalleryVideo from "@/components/MediaGalleryVideo/MediaGalleryVideo";
import MedicalPublications from "@/components/MedicalPublications/MedicalPublications";
import ContentSplitTextMap from "@/components/ContentSplitTextMap/ContentSplitTextMap";
import ContentGridMagazine from "@/components/ContentGridMagazine/ContentGridMagazine";
import GalleryGrid from "@/components/GalleryGrid/GalleryGrid";
import ContentLeftDark from "@/components/ContentLeftDark/ContentLeftDark";
import PartnerLogosSpin from "@/components/PartnerLogosSpin/PartnerLogosSpin";
import ContentTextLeftImageBg from "@/components/ContentTextLeftImageBg/ContentTextLeftImageBg";
import ContentSplitLeftImageWhite from "@/components/ContentSplitLeftImageWhite/ContentSplitLeftImageWhite";
import ContentSplitLeftVideoWhite from "@/components/ContentSplitLeftVideoWhite/ContentSplitLeftVideoWhite";
import ProfileAboutSplitGrey from "@/components/ProfileAboutSplitGrey/ProfileAboutSplitGrey";
import ContentSplitTextOnlyBlack from "@/components/ContentSplitTextOnlyBlack/ContentSplitTextOnlyBlack";
import ContentSplitTextOnly from "@/components/ContentSplitTextOnly/ContentSplitTextOnly";
import ContentSplitTextImage from "@/components/ContentSplitTextImage/ContentSplitTextImage";
import VideoGridBlack from "@/components/VideoGridBlack/VideoGridBlack";
import AboutTheAuthorWhite from "@/components/AboutTheAuthorWhite/AboutTheAuthorWhite";
import GridImageWithBtn from "@/components/GridImageWithBtn/GridImageWithBtn";
import BookAConsultation from "@/components/BookAConsultation/BookAConsultationNext";
import CaseStudySection from "@/components/CaseStudySection/CaseStudySection";
import ResolutionOfBruising from "@/components/ResolutionOfBruising/ResolutionOfBruising";
import TestimonialsTextCarousel from "@/components/TestimonialsTextCarousel/TestimonialsTextCarousel";
import SectionAnchor from "@/components/SectionAnchor/SectionAnchor";
import BlogArchiveListSection from "@/components/Blog/BlogArchiveListSection/BlogArchiveListSection";

import BgRightTxtScroll from "@/components/BgRightTxtScroll/BgRightTxtScroll";
import AboutSectionRigthVideo from "@/components/AboutSectionRigthVideo/AboutSectionRigthVideo";
import SecExpect from "@/components/SecExpect/SecExpect";
import SecCombine from "@/components/SecCombine/SecCombine";
import SecWhatCanTreat from "@/components/SecWhatCanTreat/SecWhatCanTreat";
import SecWhyChoose from "@/components/SecWhyChoose/SecWhyChoose";
import SecWhatSets from "@/components/SecWhatSets/SecWhatSets";
import SecTwoColGray from "@/components/SecTwoColGray/SecTwoColGray";


type CompWithData = ({ data }: { data: any }) => JSX.Element;

const componentMap: Record<string, CompWithData> = {
	header: Header,
	footer: Footer,

	cmp_hero_main: HeroTitleTextCta,
	cmp_profile_about_links: AboutNavigationSection,
	cmp_profile_about_split: AboutSection,
	cmp_content_grid_4_col: FournColumnsSection,
	cmp_content_split_2_col_list: DrDeSilvaOverviewSection,
	cmp_media_banner: FeaturedLogosSection,
	cmp_partner_logos: MedicalAssociationsSection,
	cmp_content_split_right_image: ArtistrySection,
	cmp_content_split_left_image: PhilanthropySection,
	cmp_content_accordion: SpecialtyTrainingSection,
	cmp_content_hero_media: ConsultationCTA,
	cmp_content_text_centered_white: TestimonialSection,
	cmp_content_text_centered_black: AboutDrDeSilvaSection,
	cmp_content_text_centered_grey: TestimonialSectionGrey,
	cmp_content_split_text_media: DeepPlaneFaceliftSection,
	cmp_media_publications: MedicalPublications,
	cmp_gallery_grid: GalleryGrid,
	cmp_content_split_text_map: ContentSplitTextMap,
	cmp_media_gallery_video: MediaGalleryVideo,
	cmp_content_grid_magazine: ContentGridMagazine,
	cmp_video_grid: VideoGrid,
	cmp_gallery_masonry: GalleryMasonry,
	cmp_hero_main_split: HarleyStreetFaceliftHero,
	cmp_content_split_text_video: ReasonsBlepharoplasty,
	cmp_content_media_slider: InThePress,
	cmp_content_text_split_grey: CandidateDeepPlane,
	cmp_faq_accordion: FrequentlyQuestions,
	cmp_gallery_slider: FacialSurgeryResultsShowcase,
	cmp_content_split_30_70_left_image_white: AboutTheAuthorWhite,
	cmp_video_grid_black: VideoGridBlack,
	cmp_patient_experience: PatientExperience,
	cmp_video_results: VideosVFR7Results,
	cmp_single_video: ClientResult,
	cmp_content_split_image_covers: AwardsAndRecognitionSection,
	cmp_split_text_2_col_strict: VfrSevenFaceliftSteps,
	cmp_hero_left: HomeHero,
	text_only_section: TextOnlySection,
	cmp_content_split_left_button: WelcomeToPractice,
	cmp_content_split_profile_intro: FacialSurgeon,
	cmp_content_grid_procedures: WhatWeOffer,
	cmp_content_split_text_buttons: VerticalFaceRestore7,
	cmp_content_blog_preview: BlogPreview,
	cmp_grid_image_with_btn: GridImageWithBtn,
	cmp_testimonial_section: PatientsSaying,
	cmp_faq_questions: QuestionsSections,
	cmp_social_feed: Insta,
	cmp_google_reviews_widget: GoogleReviews,
	cmp_profile_about_split_grey: ProfileAboutSplitGrey,
	cmp_latest_facelift: LatestFacelift,
	cmp_are_you_ready: AreYouReady,
	cmp_heading_link_section: HeadingLinkSection,
	cmp_table: DeepPlaneVsStandard,
	cmp_content_left_dark: ContentLeftDark,
	cmp_partner_logos_spin: PartnerLogosSpin,
	cmp_content_text_left_image_bg: ContentTextLeftImageBg,
	cmp_content_split_left_video_white: ContentSplitLeftVideoWhite,
	cmp_content_split_text_only_black: ContentSplitTextOnlyBlack,
	cmp_nav_anchor: PageSectionNavigation,
	anchor: SectionAnchor,
	heading_section: HeadingSection,
	cmp_content_split_left_image_white: ContentSplitLeftImageWhite,

	cmp_resolution_of_bruising: ResolutionOfBruising,
	cmp_case_study_section: CaseStudySection,
	cmp_content_split_text_image: ContentSplitTextImage,

	cmp_content_split_30_70_left_image_black: AboutTheAuthor,
	cmp_text_gray: TextGray,
	cmp_content_split_text_only: ContentSplitTextOnly,

	cmp_book_a_consultation: BookAConsultation,
	cmp_inline_form: RequestCall,
	cmp_form_interested_split: InterestedInForm,
	cmp_form_consultation_split_image: ConsultationForm,

	cmp_bg_right_txt_scroll: BgRightTxtScroll,
	cmp_profile_about_split_right_video: AboutSectionRigthVideo,
	cmp_expect_section: SecExpect,
	cmp_combine_section: SecCombine,
	cmp_what_can_treat: SecWhatCanTreat,
	cmp_why_choose: SecWhyChoose,
	cmp_what_sets: SecWhatSets,
	cmp_two_col_gray: SecTwoColGray,
	// hidden
	//procedure_header: ProcedureHeader,
	// what_is_deep_plane_facelift: WhatIsDeepPlaneFacelift,
	//facial_layers_section: FacialLayersSection,
	// vfr7_deep_plane_facelift: Vfr7DeepPlaneFacelift,
	// what_to_expect: WhatToExpect,
	// title_button_section: TitleButtonSection,

	blog_post_section: BlogPostSection,
	blog_index_list_section: BlogIndexSection,
	blog_category_list_section: BlogCategoryListSection,
	blog_tag_list_section: BlogTagListSection,
	blog_author_list_section: BlogAuthorListSection,
	blog_search_list_section: BlogSearchListSection,
	blog_archive_list_section: BlogArchiveListSection,

	gallery_root: GalleryIndex,
	gallery_procedure: ProcedureSection,
	gallery_patient: PatientSection,

	cmp_testimonials_text_carousel: TestimonialsTextCarousel,
}

const renderComponents = (
  components: PageComponent[] = [],
  componentData: ComponentData
) => {
  return components.map((component: PageComponent, index: number) => {
    const Component = componentMap[component.collection];
    if (!Component) {
      console.warn(
        `Component not found for collection: ${component.collection}`
      );
      return null;
    }

    // Special handling for root_data components
    if (component.item === "root_data") {
      return (
        <>
          {componentData[component.collection]?.root_data?.map0 && (
            <h2 className="text-3xl font-bold mb-8 text-center">
              {component.collection}
            </h2>
          )}
          <Component
            key={`${component.collection}-${index}`}
            data={componentData[component.collection]?.root_data}
          />
        </>
      );
    }

    // Regular component rendering
    const data = componentData[component.collection]?.[component.item];
    return (
      <>
        {data?.map0 && (
          <h2 className="text-3xl font-bold mb-8 text-left bg-gray-600 text-white p-4">
            {component.collection}
          </h2>
        )}
        <Component
          key={`${component.collection}-${index}`}
          data={data} />
      </>
    );
  });
};

export default function DynamicPage({ page }: PageProps) {
  if (!page) return null;
  
  return (
    <>
      <LoadingBar />
      <HtmlLinksLoader />

      {typeof page.template === "object" &&
        page.template?.head_components &&
        renderComponents(
          page.template.head_components.map((component, index) => ({
            ...component,
            key: `head-${component.collection}-${index}`
          })),
          page.componentData || {}
        )}

      {page.page_components
        ? renderComponents(
          page.page_components.map((component, index) => ({
            ...component,
            key: `page-${component.collection}-${index}`
          })),
          page.componentData || {}
        )
        : typeof page.template === "object" &&
        page.template?.main_component &&
        renderComponents(
          [{
            collection: page.template.main_component,
            item: "root_data",
            key: `main-${page.template.main_component}`
          }],
          page.componentData || {}
        )}

      {typeof page.template === "object" &&
        page.template?.foot_components &&
        renderComponents(
          page.template.foot_components.map((component, index) => ({
            ...component,
            key: `foot-${component.collection}-${index}`
          })),
          page.componentData || {}
        )}
    </>
  );
}

// Add this test data structure
const testPages = [
	{
		slug: 'test',
		title: 'Home Page',
		description: 'Welcome to our site',
		page_components: [],
		template: {
			// head_components: [
			//   {
			//     collection: "header",
			//     item: "header",
			//   },
			// ],
		},
		componentData: {},
		seo: {
			title: 'Test Page',
			description: 'This is a test page for development',
		},
	},
]



export const getServerSideProps: GetServerSideProps = async (context) => {
  const { params, query, preview = false } = context;

  const slugParts = (params?.slug as string[]) || [];

  // Skip processing if it's an API route
  if (slugParts[0] === 'api') {
    return {
      notFound: true,
    };
  }

  if (process.env.ENVIRONMENT === "production") {
    context.res.setHeader(
      'Cache-Control', "public, s-maxage=43200, stale-while-revalidate=3600"
    );
  }

  // Add new search endpoint
  if (
    slugParts[0] === process.env.NEXT_PUBLIC_BLOG_SLUG &&
    slugParts[1] === "search" &&
    query.q
  ) {
    const searchQuery = query.q as string;
    const currentPage = query.page ? parseInt(query.page as string) : 1;
    const page = await blogService.searchBlogPosts(searchQuery, currentPage);

    if (page) {
      return {
        props: {
          page,
          preview: preview,
        },
      };
    }
  }

  //for blog index page and pagination
  if (
    (slugParts[0] === process.env.NEXT_PUBLIC_BLOG_SLUG && !slugParts[1]) ||
    (slugParts[0] === process.env.NEXT_PUBLIC_BLOG_SLUG && slugParts[1] === process.env.NEXT_PUBLIC_BLOG_PAGE_SLUG && slugParts[2])
  ) {

    const currentPage =
      slugParts[1] === process.env.NEXT_PUBLIC_BLOG_PAGE_SLUG
        ? parseInt(slugParts[2])
        : 1;

    const page = await blogService.getBlogPosts(currentPage);
    if (page) {
      return {
        props: {
          page,
          preview: preview,
        },
      };
    } else {
    }

  }

  // //for blog categories
  if (
    slugParts[0] === process.env.NEXT_PUBLIC_BLOG_SLUG &&
    slugParts[1] === process.env.NEXT_PUBLIC_BLOG_CATEGORY_SLUG &&
    slugParts[2]
  ) {
    const currentPage =
      slugParts[3] === process.env.NEXT_PUBLIC_BLOG_PAGE_SLUG
        ? parseInt(slugParts[4])
        : 1;
    const categorySlug = slugParts[2];

    const page = await blogService.getBlogPostsByCategory(
      categorySlug,
      currentPage
    );
    if (page) {
      return {
        props: {
          page,
          preview: preview,
        },
      };
    }
  }

  //for blog tags
  if (
    slugParts[0] === process.env.NEXT_PUBLIC_BLOG_SLUG &&
    slugParts[1] === process.env.NEXT_PUBLIC_BLOG_TAG_SLUG &&
    slugParts[2]
  ) {
    const currentPage =
      slugParts[3] === process.env.NEXT_PUBLIC_BLOG_PAGE_SLUG
        ? parseInt(slugParts[4])
        : 1;
    const tagSlug = slugParts[2];

    const page = await blogService.getBlogPostsByTag(tagSlug, currentPage);
    if (page) {
      return {
        props: {
          page,
          preview: preview,
        },
      };
    }
  }

  //for blog authors
  if (
    slugParts[0] === process.env.NEXT_PUBLIC_BLOG_SLUG &&
    slugParts[1] === process.env.NEXT_PUBLIC_BLOG_AUTHOR_SLUG &&
    slugParts[2]
  ) {
    const currentPage =
      slugParts[3] === process.env.NEXT_PUBLIC_BLOG_PAGE_SLUG
        ? parseInt(slugParts[4])
        : 1;
    const authorSlug = slugParts[2];

    const page = await blogService.getBlogPostsByAuthor(
      authorSlug,
      currentPage
    );
    if (page) {
      return {
        props: {
          page,
          preview: preview,
        },
      };
    }
  }

  //for blog single post
  if (slugParts[0] === process.env.NEXT_PUBLIC_BLOG_SLUG && slugParts[1]) {
    const page = await blogService.getBlogPost(slugParts[1]);
    if (page) {
      return {
        props: {
          page,
          preview: preview,
        },
      };
    }
  }

  //for blog archive page
  if (
    slugParts[0] === process.env.NEXT_PUBLIC_BLOG_SLUG &&
    slugParts[1] === process.env.NEXT_PUBLIC_BLOG_ARCHIVE_SLUG &&
    slugParts[2]
  ) { 
    const currentPage =
      slugParts[3] === process.env.NEXT_PUBLIC_BLOG_PAGE_SLUG
        ? parseInt(slugParts[4])
        : 1;
    const archiveSlug = slugParts[2];
    const page = await blogService.getBlogPostsByArchive(archiveSlug, currentPage);

    if (page) {
      return {
        props: {
          page,
          preview: preview,
        },
      };
    }
  }

  //for gallery index page
  if (slugParts[0] === process.env.NEXT_PUBLIC_GALLERY_SLUG && !slugParts[1]) {
    const page = await galleryService.getGalleryCategories();
    if (page) {
      return {
        props: {
          page,
          preview: preview,
        },
      };
    }
  }

  //for gallery procedure page
  if (
    slugParts[0] === process.env.NEXT_PUBLIC_GALLERY_SLUG &&
    slugParts[1] &&
    !slugParts[2]
  ) {
    const page = await galleryService.getGalleryProcedures(slugParts[1]);
    if (page) {
      return {
        props: {
          page,
          preview: preview,
        },
      };
    }
  }

  //for gallery patient page
  if (
    slugParts[0] === process.env.NEXT_PUBLIC_GALLERY_SLUG &&
    slugParts[1] &&
    slugParts[2] === process.env.NEXT_PUBLIC_GALLERY_PATIENT_SLUG &&
    slugParts[3]
  ) {
    const page = await galleryService.getGalleryPatients(
      slugParts[1],
      slugParts[3]
    );
    if (page) {
      return {
        props: {
          page,
          preview: preview,
        },
      };
    }
  }

  // For components map page
  if (
    slugParts[0] === process.env.NEXT_PUBLIC_COMPONENTS_MAP_SLUG &&
    !slugParts[1]
  ) {
    const page = await componentService.getComponentsMap(componentMap);
    if (page) {
      return {
        props: {
          page,
          preview: preview,
        },
      };
    }
  }

  // For local development, use test data
  if (process.env.ENVIRONMENT === "development") {
    const slug = !params?.slug
      ? "home"
      : Array.isArray(params.slug)
        ? params.slug.join("/")
        : params.slug;

    const page = testPages.find((p) => p.slug === slug);

    if (page) {
      return {
        props: {
          page,
          preview: preview,
        },
      };
    }
  }

  // Pages
  const slug = !params?.slug
    ? "home"
    : Array.isArray(params.slug)
      ? params.slug.join("/")
      : (params.slug as string);

  const page = await pageService.getBySlug(slug);

  if (!page) {
    return {
      redirect: {
        destination: "/",
        permanent: false,
      },
    };
  }

  return {
    props: {
      page,
      preview: preview,
    },
  };
};
