"use client";

import { useEffect } from 'react';
import { useLoadingState } from './useLoadingState';

export function useHtmlLinksLoader() {
  const { setIsLoading } = useLoadingState();

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      const anchor = target.closest('a');
      
      if (anchor && 
          anchor.href && 
          anchor.href.startsWith(window.location.origin) && 
          !anchor.hasAttribute('download') &&
          !anchor.getAttribute('target')) {
        setIsLoading(true);
      }
    };

    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  }, [setIsLoading]);
} 