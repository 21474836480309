export const getGalleryCategoryUrl = (categoryId: string) => {
return `/${process.env.NEXT_PUBLIC_GALLERY_SLUG}/${categoryId}`;
};

export const getGalleryPatientUrl = (procedureSlug: string, patientId: string) => {
  return `/${process.env.NEXT_PUBLIC_GALLERY_SLUG}/${procedureSlug}/${process.env.NEXT_PUBLIC_GALLERY_PATIENT_SLUG}/${patientId}`;
};

export const getGalleryUrl = () => {
  return `/${process.env.NEXT_PUBLIC_GALLERY_SLUG}`;
};