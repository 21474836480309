import { createDirectus, rest, staticToken } from '@directus/sdk';

const directus = createDirectus(process.env.NEXT_PUBLIC_DIRECTUS_URL!)
  .with(rest())
  .with(staticToken(process.env.NEXT_DIRECTUS_ADMIN_TOKEN!));

export default directus;
export const getDirectusClient = () => directus;


export const getReadableImageUrl = (imageId: string, filename: string) => {
  return `${process.env.NEXT_PUBLIC_DIRECTUS_URL}/assets/${imageId}/${filename}`;
};

export const getIconUrl = (iconId: string) => {
    return `${process.env.NEXT_PUBLIC_DIRECTUS_URL}/assets/${iconId}`;
  };

