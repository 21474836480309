export const getBlogCategoryUrl = (categoryId: string) => {
return `/${process.env.NEXT_PUBLIC_BLOG_SLUG}/category/${categoryId}`;
};

export const getBlogPostUrl = (postSlug: string) => {
return `/${process.env.NEXT_PUBLIC_BLOG_SLUG}/${postSlug}`;
};

export const getBlogTagUrl = (tagId: string) => {
return `/${process.env.NEXT_PUBLIC_BLOG_SLUG}/tag/${tagId}`;
};

export const getBlogPageUrl = (page: number) => {
return `/${process.env.NEXT_PUBLIC_BLOG_SLUG}/page/${page}`;
};

export const getBlogAuthorUrl = (authorSlug: string) => {
return `/${process.env.NEXT_PUBLIC_BLOG_SLUG}/${process.env.NEXT_PUBLIC_BLOG_AUTHOR_SLUG}/${authorSlug}`;
};
